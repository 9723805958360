<template>
  <div>
    <ys-page-list
        ref="table"
        action="expert/List"
        :params="params"
        :multiColumn="false"
        :tableHeight="height-300"
        :headerColumns="headerColumns"
        @on-selection-change="checkItem"
        :isEmptySelect="isEmptySelect">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入专家姓名或电话" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
        <FormItem label="子公司数据" prop="isTop">
          <Checkbox v-model="params.isTop" border>不包含</Checkbox>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import components from '@/mixins/components.js';
import {getRegionByCode} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList, components],
  props: {
    serveOrgId: {
      type: [Number, String],
      default: ''
    },
    IDs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {},

  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 100, title: '照片', key: 'pictureId', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 100, title: '姓名', key: 'iname', align: 'left'},
        {width: 130, title: '联系电话', key: 'phone', align: 'left', renderConfig: {type: 'phoneDesensitise'}},
        {width: 180, title: '身份证', key: 'identityCode', align: 'left', renderConfig: {type: 'IDDesensitise'}},
        {width: 150, title: '资质', key: 'position', align: 'left'},
        {width: 200, title: '专业方向', key: 'territory', align: 'center'},
        {
          width: 220, title: '所属区域', key: 'areaId', align: 'center', /* renderConfig: {type: 'area'}, */
          render: (h, params) => {
            return h('div', {
              attrs: {
                class: ''
              },
            }, [h('span', {domProps: {innerHTML: getRegionByCode(`${params.row.areaId}`)}})])
          }
        },
        {width: 150, title: '服务年限', key: 'workYears', align: 'center'},
      ],
      params: {
        keyword: '',
        orgId: '',
        isTop: false,
      },
      modal: {}
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.params.orgId = this.serveOrgId || this.midData[0]?.serveOrgId || '';
    this.getList();
  },

  methods: {}

}
</script>
